// Generate .bg-gradient-X from gradient color map
@each $number, $colors in $gradients {
  .bg-gradient-#{$number} {
    @include bg-gradient(
               map-get($colors, gstart),
               map-get($colors, gstop)
             );
  }
}

// Generate .bg-accent-X, .text-accent-X, .btn-accent-X and .btn-outline-accent-X from accent color map
@each $number, $colors in $accents {
  .bg-accent-#{$number} {
    @include bg-color(
              map-get($colors, color)
             );
  }

  .text-accent-#{$number} {
    @include text-color(
              map-get($colors, color)
             );
  }

  .text-accent-contrast-#{$number} {
    @include text-color(
                    map-get($colors, contrast)
    );
  }
}

.img-simple-border {
  border: 1px $img-simple-border solid;
}

.icon-with-label {
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  margin-right: $spacer * 1.5;
}

.icon-with-label-text {
  line-height: 20px;
  margin-left: $spacer / 2.66;
  margin-top: -2px;
}

.link-soft {
  color: $body-color;

  &:hover,
  &:focus,
  &:active {
    color: $body-color;
  }
}
