// Buttons
.btn-xs {
  //($padding-y, $padding-x, $font-size, $line-height, $border-radius)
  @include button-size(.25rem, .5rem, .875rem, 16px, $border-radius);
}

.btn-md {
  //($padding-y, $padding-x, $font-size, $line-height, $border-radius)
  @include button-size(1rem, 2rem, 1rem, 16px, $border-radius);
}

.btn-lg {
  //($padding-y, $padding-x, $font-size, $line-height, $border-radius)
  @include button-size(1rem, 2rem, 1.25rem, 16px, $border-radius);
}

.btn-pill {
  border-radius: 100px;
}

// Give Bootstrap buttons a bevel
.btn-primary,
.btn-success,
.btn-warning,
.btn-danger,
.btn-secondary,
.btn-info,
.btn-dark {
  @include button-bevel();
}

// Give white standard button a special bevel
.btn-light {
  @include bevel($dark: false);

  &.btn-lg {
    @include bevel($dark: false, $bottom-strength: -2px);
  }
}

.btn-secondary {
  &:hover,
  &:active,
  &:focus,
  & {
    color: #fff;
  }
}

//[class^='btn-outline-'],
//[class*=' btn-outline-'] {
//  border-width: 5px;
//}

// Colored buttons
// Generates classes for all accent colors in $accents, .btn-accent-1, .btn-accent-2, etc.
@each $number, $colors in $accents {
  .btn-accent-#{$number} {
    //($background, $border, $active-background: darken($background, 7.5%), $active-border: darken($border, 10%)) {

    @include button-variant(
        map-get($colors, color),
        map-get($colors, border)
    );
    @include button-bevel();
  }

  .btn-outline-accent-#{$number} {
    @include button-outline-variant(
        map-get($colors, color)
    );
  }
}

.btn-icon {
  .icon {
    line-height: 1px;
    margin: 0;
    vertical-align: middle;
  }

  .btn-icon-label {
    margin-left: 5px;
  }

  &.btn-lg {
    .btn-icon-label {
      margin-left: 10px;
    }
  }
}


