// ---------------------------------
// ---------- CHARMING -------------
// ---------------------------------

$accent-1: #35bd49 !default;
$accent-2: #353844 !default;
$accent-3: #3b3b3b !default;

$primary: #2284fe !default;
$secondary: #78baf8 !default;
$success: $accent-1 !default;
$info: #5a7ec5 !default;
$warning: #f0a249 !default;
$danger: #fc2344 !default;
$light: #e5e5e5 !default;
$dark: #3b3b3b !default;

// Accent colors
// Used to generate: bg-accent-X, text-accent-X, ...
$accents: (
  1: (
    color: $accent-1,
    border: #239834,
    gstart: #34bc48,
    gstop: #2caa3f,
    contrast: #fff
  ),
  2: (
    color: $accent-2,
    border: #24262c,
    gstart: #353844,
    gstop: #444860,
    contrast: #d8d9dd
  ),
  3: (
    color: $accent-3,
    gstart: #3b3b3b,
    gstop: #2e2e2e,
    border: #272727,
    contrast: #fff
  )
) !default;

// Fancy UI gradients
// Used to generate: bg-gradient-X, ...
$gradients: (
  1: (
    gstart: #353844,
    gstop: #444860
  ),
  2: (
    gstart: #35bd49,
    gstop: #7ec989
  ),
  98: (
    // Dashboard background up for changes
    gstart: #c2cad1,
    gstop: #8e9eab
  )
) !default;

$body-color-soft: #828282 !default;

// Dark and light colors (text color for dark/light elements)
$dark-color: #353844 !default;
$dark-gstart: #353844 !default;
$dark-gstop: #444860 !default;
$dark-contrast: #fff !default;
$dark-separator: #4e5161 !default;
$dark-contrast-highlight: #b8f5c3 !default;


$light-color: #f9f9f9 !default;
$light-gstart: #fff !default;
$light-gstop: #f9f9f9 !default;
$light-contrast: #343434 !default;
$light-separator: #c8c8c8 !default;
$light-contrast-highlight: #31a742 !default;

// Buttons
$colored-button-text-shadow: rgba(0, 0, 0, .07) !default;

//Forms
$charming-form-input-bg: rgba(255, 255, 255, .8) !default;

// Navigation
$navbar-dark-mobile-nav-bg: rgba(255, 255, 255, .2) !default;
$navbar-dark-mobile-nav-highlight: rgba(255, 255, 255, .4) !default;

$navbar-light-mobile-nav-bg: rgba(0, 0, 0, .06) !default;
$navbar-light-mobile-nav-highlight: rgba(0, 0, 0, .10) !default;

// Features
$img-simple-border: #2d3842 !default;


// ---------------------------------
// ---------- BOOTSTRAP ------------
// ---------------------------------

$body-color: #373a3c !default; //BS

$green: #35bd49 !default;
$cyan: #4ac1ee !default;
$orange: #fba91e !default;
$red: #fa2840 !default;

// Adding accents to bootstrap
$theme-colors: () !default;
$theme-colors: map-merge((
  accent-1: $accent-1,
  accent-2: $accent-2,
  accent-3: $accent-3
), $theme-colors);

// Navbar
$navbar-dark-color: rgba(255, 255, 255, .9) !default; //BS
$navbar-dark-hover-color: rgba(255, 255, 255, .95) !default; //BS
$navbar-dark-toggler-border-color: rgba(255, 255, 255, .1) !default; //BS

// Navigation
$navbar-light-color:                #343434 !default; //BS
$navbar-light-active-color:         #4c4c4c !default; //BS

$navbar-dark-color:                 #fff !default; //BS
$navbar-dark-hover-color:           darken($navbar-dark-color, 13%) !default; //BS
$navbar-dark-active-color:          #fff !default; //BS
$navbar-dark-disabled-color:        #fff !default; //BS

// Forms
$custom-control-indicator-checked-bg:         #494949 !default; //BS
$custom-checkbox-indicator-indeterminate-bg: #494949 !default; //BS

// scss-lint:disable ColorVariable
$custom-control-indicator-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #494949 !default; //BS
// scss-lint:enable ColorVariable

$input-focus-border-color:   #898989 !default; //BS

// Links
$link-color: $accent-1 !default; //BS
